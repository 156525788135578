<template>
  <div v-click-outside="testClickOutside" style="display: inline-block;" >
    <el-popper
      ref="balanceFilterRef"    
      :visible="balanceFilterVisible"
      trigger="click"
      effect="light"
      >
      <template #default>
        <div style="block">
          <div class="el-table-filter__content">
            <el-scrollbar wrap-class="el-table-filter__wrap">            
              <el-select
                  class="form-select-solid px-5"
                  placeholder="Select"
                  v-model="data.filterType"
                  style="width:120px;"
              >
                <el-option 
                  v-for="(value) in Object.values(BalanceFilterType)" 
                  :label="value" 
                  :value="value"
                  :key="value">
                </el-option>              
              </el-select>
              <input
                v-model="data.amount"
                min="0.01" 
                step="0.01"
                type="number"
                class="form-control form-control-solid"
                style="display: inline-block;width: 110px;"
              />            
            </el-scrollbar>
          </div>
          <div class="el-table-filter__bottom">
            <button
              type="button"
              @click="confirmFilter"
            >
              Confirm
            </button>
            <button type="button" @click="resetFilter">
              Reset
            </button>
          </div>
        </div>
      </template>
      <template #trigger>
          <span
            class="el-table__column-filter-trigger el-none-outline"          
          >
            <i class="el-icon-arrow-up" v-if="balanceFilterVisible" @click="hideFilter"></i>
            <i class="el-icon-arrow-down" v-else @click="showFilter"></i>
          </span>
        </template>
    </el-popper>
  </div>
</template>
  
  <script lang="ts">
  import { defineComponent, ref, toRefs, unref } from 'vue'
  import { BalanceFilterType } from '@/types/StudentModel'
  import { ClickOutside as vClickOutside } from 'element-plus/lib/directives'
import { isArguments } from 'lodash';
  
  export default defineComponent({
    name: 'balance-filter',
    props: {
      setBalanceFilter : {
        type:Function,
        default: () => ({}),
      },
      balanceFilterData: {} as any
    },
    setup(props) {
      const balanceFilterVisible = ref(false);
      const {balanceFilterData} = toRefs(props);   
      const selectRef = ref();
      const data = ref({amount : balanceFilterData.value?.amount, filterType:balanceFilterData.value?.filterType})

      const hideFilter = () => {
        balanceFilterVisible.value = false
      }

      const showFilter = () => {
        balanceFilterVisible.value = true
      }

      const confirmFilter = () => {
        props.setBalanceFilter({filterType: data.value.filterType, amount: data.value.amount});
        hideFilter();
      }

      const resetFilter = () => {
        data.value.filterType = null;
        data.value.amount = 0;
        confirmFilter();
      }

      const testClickOutside = (event) =>{
        if (!event.target.closest('.el-select-dropdown')){
          hideFilter();
        }
      }

      return {
        balanceFilterVisible,
        hideFilter,
        showFilter,
        confirmFilter,
        BalanceFilterType,
        data,
        resetFilter,
        testClickOutside,
        selectRef
      }
    },
  })
  </script>