
  import { defineComponent, ref, toRefs, unref } from 'vue'
  import { BalanceFilterType } from '@/types/StudentModel'
  import { ClickOutside as vClickOutside } from 'element-plus/lib/directives'
import { isArguments } from 'lodash';
  
  export default defineComponent({
    name: 'balance-filter',
    props: {
      setBalanceFilter : {
        type:Function,
        default: () => ({}),
      },
      balanceFilterData: {} as any
    },
    setup(props) {
      const balanceFilterVisible = ref(false);
      const {balanceFilterData} = toRefs(props);   
      const selectRef = ref();
      const data = ref({amount : balanceFilterData.value?.amount, filterType:balanceFilterData.value?.filterType})

      const hideFilter = () => {
        balanceFilterVisible.value = false
      }

      const showFilter = () => {
        balanceFilterVisible.value = true
      }

      const confirmFilter = () => {
        props.setBalanceFilter({filterType: data.value.filterType, amount: data.value.amount});
        hideFilter();
      }

      const resetFilter = () => {
        data.value.filterType = null;
        data.value.amount = 0;
        confirmFilter();
      }

      const testClickOutside = (event) =>{
        if (!event.target.closest('.el-select-dropdown')){
          hideFilter();
        }
      }

      return {
        balanceFilterVisible,
        hideFilter,
        showFilter,
        confirmFilter,
        BalanceFilterType,
        data,
        resetFilter,
        testClickOutside,
        selectRef
      }
    },
  })
  