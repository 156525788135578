<template>
  <div class="col-md-12">
      <div class="card-body p-5" style="background-color: white;margin-bottom: 10px;font-weight: 600;">
      <div class="col-md-3" style="display:inline-block;"># of active : {{noOfActiveStudents}}</div>
      <div class="col-md-3" style="display:inline-block;"># of active F-1 : {{noOfActiveF1}} </div> 
      <div class="col-md-3" style="display:inline-block;"># of active non I-20: {{noOfActiveNonI20}} </div>
      <div class="col-md-3" style="display:inline-block;">total tuition balance : ${{totalBalance}} </div>
      </div>
  </div>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    </div>
    <div class="col-md-2" align="left">
      <button
            class="btn btn-sm btn-success"
            id="student_download_button"
            @click="downloadStudents"
        >
        Download
        </button>
    </div>
    <div class="col-md-10" align="right">
      <div class="me-4">
        <el-input
            v-model="search.searchValue"
            placeholder="Student Id / Name / Phone / Email / Category / Nationality / Advisor / Sevis No / State / Agent"
            class="col-md-10"
            style="padding-right:10px;"/>
        <button
            @click="openCreateStudent"
            class="btn btn-sm btn-primary"
            id="student_create_button"
        >
        Create
        </button>
      </div>
    </div>
  </div>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="student_list">
    <el-table :data="pagedTableData" style="width: 100%;font-size:13px;" @filter-change="handleFilterChange" @cell-click="selectStudent">    
      <el-table-column prop="student_id" label="Student Id" :min-width="12" sortable class-name="clickable"></el-table-column>  
      <el-table-column prop="name" label="Name" :min-width="28">
          <template v-slot="scope">
              <label> {{scope.row.first_name}} {{scope.row.last_name}}</label>
          </template>
      </el-table-column>
      <el-table-column prop="phone" label="Phone" :min-width="13"></el-table-column>  
      <el-table-column prop="email" label="Email" :min-width="25"></el-table-column>        
      <el-table-column prop="status" label="Status"  :min-width="10"
          :filters="studentStatuses"
          column-key="status-column">
        <template v-slot="scope">
            <span v-if="scope.row.status == 'Active'" class="badge-light-success badge fs-7 fw-bolder my-2">{{scope.row.status}}</span>
            <span v-else-if="scope.row.status == 'Inactive'" class="badge-light-danger badge fs-7 fw-bolder my-2">{{scope.row.status}}</span>
            <span v-else-if="scope.row.status == 'Draft'|| scope.row.status == 'Initial' || scope.row.status == 'COS' || scope.row.status == 'Reinstate'" class="badge-light-primary badge fs-7 fw-bolder my-2">{{scope.row.status}}</span>
            <span v-else-if="scope.row.status == 'Exit'" class="badge-light-warning badge fs-7 fw-bolder my-2">{{scope.row.status}}</span>       
        </template>
      </el-table-column>  
      <el-table-column prop="session_code" label="Session" :min-width="10">
        <template v-slot="scope">
          <label v-if="scope.row.session_code"> {{scope.row.session_code}}</label>
          <label v-else>N/A</label>
        </template>
      </el-table-column>
      <el-table-column prop="advisor_name" label="Advisor" :min-width="15">
          <template v-slot="scope">
              <label> {{scope.row.advisor ? scope.row.advisor.first_name + " " + scope.row.advisor.last_name: ""}}</label>
          </template>
      </el-table-column>    
      <el-table-column prop="agent.name" label="Agent" :min-width="12"></el-table-column>
      <el-table-column prop="balance" label="Balance" :min-width="10"
          column-key="balance-column">
          <template #header>
            Balance
            <BalanceFilter
              v-bind:setBalanceFilter="setBalanceFilter"
              v-bind:balanceFilterData="balanceFilterData"/>
          </template>
          <template v-slot="scope">          
              {{scope.row.balance}}
          </template>
      </el-table-column>
    </el-table>
    <div align="right">
      <el-pagination 
        layout="total, sizes, prev, pager, next" 
        v-model:page-size="pageSize" 
        :page-sizes="pageSizes"
        :total="getFilteredStudentList().length" 
        @size-change="handleSizeChange"
        @current-change="setPage">
      </el-pagination>
    </div>
  </div>
  <!--end::Row-->

</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import CommonService from "@/core/services/CommonService";
import moment from "moment";
import BalanceFilter from "@/views/student/BalanceFilter.vue";
import { BalanceFilterType } from "@/types/StudentModel";
import { useStore } from "vuex";

export default defineComponent({
  name: "student-list",
  components: {
    BalanceFilter
  },
  setup() {
    const router = useRouter();
    const studentListData : any[] = reactive([]);
    const studentStatuses = ref<any>([]);
    const filters = reactive({status : []});
    const store = useStore();
    const balanceFilterData = reactive({filterType: null, amount: 0});

    const getStudentsSummary = async () => {
        var today = new Date();
        await new Promise((resolve, reject) => {
            ApiService.setHeader();            
            ApiService.get("student/date/"+today)
                .then(({ data }) => {

                    Object.assign(studentListData,data.data.sort((a, b) => a.status.localeCompare(b.status)));
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    console.log('getStudentsSummary error => '+response.data.errors);
                    reject(new Error ('getStudentsSummary error => '+response.data.errors));
                });
        });
    }

    const pageSize = ref(20);
    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredStudentList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })

    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    let page = reactive({pageValue : 1});

    const pagedTableData = computed(()=>{
      return getFilteredStudentList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
     })

    const setBalanceFilter = (bFilter) => {
      balanceFilterData.amount = bFilter.amount;
      balanceFilterData.filterType = bFilter.filterType;
    }

    const getFilteredStudentList = () => {
      return studentListData.filter(data => (!search 
                                        || (data.first_name + " " + data.last_name).toLowerCase().includes(search.searchValue.toLowerCase())
                                        || data.student_id?.toLowerCase().includes(search.searchValue.toLowerCase())
                                        || data.phone?.toLowerCase().includes(search.searchValue.toLowerCase())
                                        || data.email?.toLowerCase().includes(search.searchValue.toLowerCase())
                                        || data.nationality?.toLowerCase().includes(search.searchValue.toLowerCase())
                                        || (data.advisor && (data.advisor?.first_name + " "+ data.advisor?.last_name).toLowerCase().includes(search.searchValue.toLowerCase()))
                                        || data.sevis_no?.toLowerCase().includes(search.searchValue.toLowerCase())
                                        || (data.address && data.address?.state?.toLowerCase().includes(search.searchValue.toLowerCase()))
                                        || (data.agent && data.agent?.name.toLowerCase().includes(search.searchValue.toLowerCase())))
                                        && (filters.status.length > 0 ? filters.status.some(x=>x == data.status) : true)
                                        && (balanceFilterData && balanceFilterData.filterType != null ? filterBalance(balanceFilterData, data.balance): true));
    }

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }

    const noOfActiveStudents = computed(()=>{
      return studentListData.filter(data => data.status =='Active').length;
    })

    const noOfActiveF1 = computed(()=>{
      return studentListData.filter(data => data.status =='Active' && data.admission_category == 'F-1').length;
    })

    const noOfActiveNonI20 = computed(()=>{
      return studentListData.filter(data => data.status =='Active' && data.admission_category == 'Non I-20').length;
    })

    const totalBalance = computed(()=>{
      return studentListData.reduce(function(a, b){return +a + +b['balance'];}, 0);
    })


    const selectStudent= (row, column) =>{
      if(column.property == "student_id"){
        router.push({ name: "student-profile", params: { id: row.id } });
      }
    }

    const openCreateStudent= () =>{
      router.push({ name: "student-profile", params: { id: 0 } });
    }

    const handleFilterChange = (e) =>{
      const propertyName = Object.getOwnPropertyNames(e)[0];
      const value = e[propertyName];

      if(propertyName == 'status-column'){
        filters.status = value;
      }
    }

    const filterBalance = (filter, balance) => {
      var amount = filter.amount;
      var filterType = filter.filterType;

      if(filterType == BalanceFilterType.EqualTo){
          return balance == amount;
      }
      else if(filterType == BalanceFilterType.GreaterThanOrEqualTo){
          return balance >= amount;
      }
      else if(filterType == BalanceFilterType.GreaterThan){
          return balance > amount;
      }
      else if(filterType == BalanceFilterType.LessThanOrEqualTo){
          return balance <= amount;
      }
      else if(filterType == BalanceFilterType.LessThan){
          return balance < amount;
      }

      return true;
    }

    const getTypes = async() => {
      return new Promise((resolve, reject) => { 
        ApiService.setHeader();
        ApiService.get("student/types")
        .then(({ data }) => {
            data.studentStatuses.forEach(element => {
              var statusObj = {};
              statusObj['text'] = element;
              statusObj['value'] = element;
              studentStatuses.value.push(statusObj);
            });
            resolve(data.data);
        })
        .catch(({ response }) => {
          reject('getTypes student error => '+response.data.errors);
          console.log('getTypes student error => '+response.data.errors);
        });
      });
    }

    const downloadStudents = ()=>{
      let currentStudentList = getFilteredStudentList().map(x=>{ return { 
        StudentId: x.student_id, 
        SevisNo : x.sevis_no,
        FirstName : x.first_name,
        LastName : x.last_name,
        Phone : x.phone, 
        Email : x.email,
        DOB : x.birth_date ? moment(x.birth_date).format('MM/DD/YYYY'): '',
        Address : x.address? x.address?.address1 + " " + x.address?.address2 + " " + x.address?.city + " " + x.address?.state + " " + x.address?.zipcode : '',
        Nationality : x.nationality,
        Category : x.admission_category,
        Agent : x.agent? x.agent?.name : '',
        Advisor : x.advisor? x.advisor?.first_name + " "+ x.advisor?.last_name : '',
        Status : x.status,
        SessonCode : x.session_code,
        StartDate : x.start_date ? moment(x.start_date).format('MM/DD/YYYY'): '',
        EndDate : x.end_date ? moment(x.end_date).format('MM/DD/YYYY'): '',
        AttendanceRate : x.attendance_rate,
        Balance : x.balance        
      };});
      let today = new Date();
      let fileName = 'Student List '+ today.toLocaleString();
      CommonService.exportToCsv(fileName, currentStudentList);
    }

    onMounted(() => {
      getTypes();
      setCurrentPageBreadcrumbs("Student List", ["Student"]);
      getStudentsSummary();
    });

    return {
      selectStudent,
      studentListData,
      pagedTableData,
      pageSize,
      pageSizes,
      search,
      studentStatuses,
      downloadStudents,
      handleSizeChange,
      getFilteredStudentList,
      noOfActiveStudents,
      noOfActiveF1,
      noOfActiveNonI20,
      totalBalance,
      setPage,
      openCreateStudent,
      filters,
      handleFilterChange,
      balanceFilterData,
      setBalanceFilter
    };
  },
});
</script>

<style scoped>
  .el-table__row{
    cursor:pointer;
  }

  *>>> .el-table th>.cell.highlight{
    background-color:transparent;
    padding: 0px;
  }

  *>>> .cell {
      text-align:center;
  }

  *>>> .el-table{
    color:#181C32;
  }

  *>>> .el-table td{
    padding: 6px 0;
  }

  *>>> .clickable{
    cursor:pointer;
  }
</style>

